<template>
  <el-container class="auLayout">
    <!-- 侧边栏 -->
    <el-aside class="auLeft" :width="asideShow?'200px':'80px'">
      <au-aside></au-aside>
    </el-aside>
    <el-container class="auRight">
      <!-- 顶部导航 -->
      <el-header class="auHeader" height="64px">
        <au-header v-model:asideShow="asideShow"></au-header>
      </el-header>
      <!-- 主体内容 -->
      <el-main class="auMain">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AuHeader from "@/views/admin/components/auHeader.vue";
import auAside from "@/views/admin/components/auAside.vue";
export default {
  components: { AuHeader,auAside },
  data() {
    return {
      asideShow:true,
    };
  },
  watch:{
    asideShow(val){
      console.log(val)
    }
  }
};
</script>

<style lang="scss" scoped>
.auLayout {
  height: 100vh;
  width: 100vw;
  min-width: var(--au-width);
}
.auLeft {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  transform: all .3s;
}
.auRight {
  height: 100vh;
}
.auHeader {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.auMain {
  background: var(--au-app-bg-color);
  height: calc(100vh - 64px);
  overflow: auto;
}
::v-deep {
  ::-webkit-scrollbar {
    width: 7px;
    height: 8px;
    background-color: #c7c9cc;
    transition: background-color 0.3s ease-in-out;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 8px;
    background-color: #c7c9cc;
    transition: background-color 0.3s ease-in-out;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    height: 50px;
    border-radius: 5px;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    transition: background-color 0.3s ease-in-out;
  }
  ::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: rgba(144, 147, 153, 0.3);
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
}
</style>
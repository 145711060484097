<template>
  <el-row class="page" align="middle" justify="space-between">
    <div class="left">
      <el-row class="leftPage" align="middle">
        <!-- 侧边收起按钮 -->
        <i
          class="asideBtn"
          :class="asideShow ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
          @click="onAsideBtn"
        ></i>
        <!-- 搜索栏 -->
        <au-header-search
          v-model="searchText"
          @submit="onSearchSubmit"
        ></au-header-search>
      </el-row>
    </div>
    <el-row class="right">
      <au-header-icons></au-header-icons>
      <!-- 用户头像和快捷信息 -->
      <au-user></au-user>
    </el-row>
  </el-row>
</template>

<script>
import auHeaderSearch from "./header/auHeaderSearch.vue";
import AuUser from "./header/auUser.vue";
import auHeaderIcons from "./header/auHeaderIcons.vue";
export default {
  components: { auHeaderSearch, AuUser, auHeaderIcons },
  data() {
    return {
      searchText: "",
    };
  },
  watch: {},
  props: {
    asideShow: Boolean,
  },
  methods: {
    onSearchSubmit() {
      console.log(this.searchText);
    },
    onAsideBtn() {
      this.$emit("update:asideShow", !this.asideShow);
    },
  },
  created() {
    console.log(this.asideShow);
  },
};
</script>

<style lang="scss" scoped>
.leftPage {
  & > * {
    margin-right: 16px;
    &:last-child {
      margin: 0;
    }
  }
  .asideBtn {
    font-size: 24px;
    cursor: pointer;
  }
}

.page {
  height: 100%;
}
</style>
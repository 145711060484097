<template>
  <div class="page">
    <!-- logo -->
    <a class="logo" href="/">
      <img :src="logo" />
    </a>
    <!-- 菜单栏 -->
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose">
      <el-menu-item index="2">
        <i class="el-icon-menu"></i>
        <template #title>导航二</template>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-document"></i>
        <template #title>导航三</template>
      </el-menu-item>
      <el-menu-item index="4">
        <i class="el-icon-setting"></i>
        <template #title>导航四</template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("@/assets/logo.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 0;
  overflow: auto;
  width: 200px;
}
.logo {
  width: 100%;
  padding: 8px 20px;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
  img {
    width: 70%;
  }
}
</style>
<template>
  <el-popover placement="bottom-end" :width="200" trigger="hover">
    <template #reference>
      <el-row class="page">
        <el-avatar class="avatar" size="small" :src="avatarUrl"></el-avatar>
        <el-tag>免费版</el-tag>
      </el-row>
    </template>
    <div class="items">
      <div class="name">love</div>
      <div class="info">
        <div>账户ID:</div>
        <span>TuDouCha100000</span>
        <i class="copy el-icon-copy-document"></i>
      </div>
      <div class="subList">
        <div class="ixs">
          <i class="el-icon-user-solid"></i>
          <div>个人中心</div>
        </div>
        <div class="ixs">
          <i class="el-icon-user-solid"></i>
          <div>账户管理</div>
        </div>
      </div>
      <div class="outlogin">退出登录</div>
    </div>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
      avatarUrl: require("@/assets/avatar.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.copy {
  cursor: pointer;
}
.items {
  display: flex;
  flex-flow: column nowrap;
  padding: 8px 0;
  & > * {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
  .outlogin {
    cursor: pointer;
    text-align: center;
    padding: 8px;
    background: rgb(245, 245, 245);
  }
  .ixs {
    display: flex;
    align-items: center;
    padding: 4px 0;
    color: var(--au-color-font);
    cursor: pointer;
    font-weight: 400;
    line-height: 1.42;
    transition: all 0.3s;
    i{
      margin-right: 8px;
    }
    &:hover {
      color: var(--au-color);
    }
  }
  .name {
    font-size: 16px;
    color: var(--au-color-font);
    font-weight: bold;
  }
  .info {
    display: flex;
    align-items: center;
    &>*{
      margin-right: 4px;
    }
    span {
      color: var(--au-color-font);
    }
  }
}
.page {
  .avatar{
    margin-right: 4px;
  }
  align-items: center;
  cursor: pointer;
}
</style>
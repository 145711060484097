<template>
  <div class="search">
    <input
      placeholder="搜索播主、商品、直播间、抖音小店"
      class="auSearch"
      type="text"
       v-model="input"
       @keydown.enter="$emit('submit')"
    />
    <i class="icon el-icon-search"></i>
  </div>
</template>

<script>
export default {
    data(){
        return {
            input:""
        }
    },
    watch:{
        input(val){
            this.$emit("update:modelValue",val)
        },
        modelValue(val){
            this.input=val;
        }
    },
    props:{
        modelValue:{
            type:String,
            default:""
        }
    }
};
</script>

<style lang="scss">
.search {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &:hover {
    .auSearch {
      width: 300px;
      border: 1px solid var(--au-color);
      padding-left: 16px;
    }
  }
  .auSearch {
    border: 1px solid var(--au-app-bg-color);
    width: 32px;
    outline: none;
    height: 32px;
    border-radius: 32px;
    transition: all 0.2s;
    box-sizing: border-box;
    padding: 0 16px;
    &:focus {
      width: 300px;
      border: 2px solid var(--au-color);
      padding-left: 16px;
    }
  }
  .icon {
    font-size: 18px;
    font-weight: bold;
    color: var(--au-color);
    position: absolute;
    right: 0;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<template>
  <div class="page">
    <!-- 插件 -->
    <el-tooltip
      class="item"
      effect="dark"
      content="浏览器插件下载"
      placement="bottom"
    >
      <el-button class="icon" circle icon="el-icon-download"></el-button>
    </el-tooltip>
    <!-- 客服 -->
    <el-popover
      placement="bottom"
      :width="120"
      trigger="hover"
      popper-class="auPoperNoPadding"
    >
      <template #reference>
        <el-button class="icon" circle icon="el-icon-service"></el-button>
      </template>
      <div class="codePage">
        <img class="codes" :src="codes.url" />
        <div class="title">{{ codes.title }}</div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codes: {
        url: require("@/assets/ercode.png"),
        title: "客服微信",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
$mg: 20px;
.codePage {
  .codes {
    width: 120px;
    height: 120px;
  }
  .title {
    text-align: center;
    padding: 8px;
    font-size: 16px;
    background: #f4f4f4;
  }
}

.icon {
  padding: 0;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c0c4cc;
  color: #fff;
  border: none;
  font-size: 14px;
  min-width: auto;
  min-height: auto;
  transition: all 0.3s;
  margin: 0;
  &:hover {
    background: #abafb6;
  }
}
.page {
  display: flex;
  align-items: center;
  padding: 0 $mg;
  margin: 0 $mg;
  border-right: 1px solid var(--au-app-bg-color);
  &>*{
    margin-right: $mg;
    &:last-child{
      margin-right: 0;
    }
  }
}
</style>